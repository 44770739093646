<template lang="pug">
.wrapper.row.justify-between.items-center.relative-position
    template(v-for="item in links")
        router-link.link.row.q-px-md.q-py-sm.text-primary.fast-link(
            v-if="item.condition"
            :to="city ? `/${city.slug}${item.link}` : item.link"
            :title="`${item.title}`")
            q-icon(
                :name="`etabl:${item.icon}`"
                color="primary"
                size="sm"
            ).q-mr-xs
            .font-16.q-ml-sm
                | {{ item.title }}
</template>

<script>
import { useQuasar } from 'quasar';
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'FastLinks',
    setup() {
        const store = useStore();
        const route = useRoute();
        const q = useQuasar();

        const windowWidth = computed(() => {
            if(process.env.CLIENT) {
                return window.innerWidth;
            }  else return '';
        });

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const links = [
            {
                title: 'Витамины и микроэлементы',
                link: '/catalog/vitaminy-i-mikroelementy=000000016',
                icon: 'vitamins',
                condition: true,
            },
            {
                title: 'Простуда и грипп',
                link: '/catalog/prostuda-i-gripp=000000169',
                icon: 'virus',
                condition: true,
            },
            {
                title: 'Красота и уход',
                link: '/catalog/krasota-i-uхod=000000199',
                icon: 'beauty',
                condition: true,
            },
            {
                title: 'Медицинские приборы',
                link: '/catalog/pribory-medicinskie=000000346',
                icon: 'devices',
                condition: windowWidth.value >= 1280,
            },
            {
                title: 'Оптика',
                link: '/catalog/optika=000000569',
                icon: 'optics',
                condition: windowWidth.value >= 1280,
            },
            {
                title: 'Ортопедия',
                link: '/catalog/ortopediya=000000496',
                icon: 'ortoped',
                condition: windowWidth.value >= 1280,
            },
        ];

        return {
            links,
            city,
            windowWidth,
        };
    },
};
</script>

<style lang="scss" scoped>

.fast-link {
    &:hover {
        border-radius: 16px;
        background: $secondary;
        color: $positive !important;
    }
}
</style>
